// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; 

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAqcaQvBqH6RZjPOVKrFXrOHtWGqD8Rf1c",
  authDomain: "auxiliostaty.firebaseapp.com",
  projectId: "auxiliostaty",
  storageBucket: "auxiliostaty.appspot.com",
  messagingSenderId: "1087031761040",
  appId: "1:1087031761040:web:aeb0df67930d0e33fea015",
  measurementId: "G-60JCH23XWY"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

const db = getFirestore(app);

export { auth, db }; 
