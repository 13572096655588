import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  TextField,  
  Slide 
} from "@mui/material";
import { motion } from "framer-motion";
import { FaFacebook, FaGoogle, FaPhoneAlt, FaWhatsapp, FaQuestionCircle } from "react-icons/fa";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import remolque1 from "../img/444973070_378034598592791_8047239383939790848_n.webp";
import camion from "../img/341044189_744876224008904_4705133358634267220_n.webp";
import partner from "../img/partner.webp";
import palio from "../img/palioRojo.webp";
import StarIcon from "@mui/icons-material/Star";
import png from "../img/img1.webp";
import carga from "../img/carga.webp";
import chata from "../img/chata.webp";
import clasico from "../img/clasico.webp";
import auto from "../img/auto.webp";
import { Helmet } from "react-helmet";
import auto2 from "../img/auto2.webp";
import camion2 from "../img/camion2.webp";
import trafic from "../img/trafic.webp";
import auto3 from "../img/auto3.webp";
import chata3 from "../img/chata3.webp";
import styled, { keyframes } from "styled-components";
import logoTaty from "../img/logo Taty.png";
import AOS from "aos";
import EditIcon from '@mui/icons-material/Edit';
import { FaWebAwesome } from "react-icons/fa6";
import { auth } from "../firebase"; 
import { onAuthStateChanged } from "firebase/auth";
import { MdOutlineReviews } from "react-icons/md";

import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  updateDoc,
} from "firebase/firestore"; 
import { db } from "../firebase"; //Configuración de Firebase
import Swal from "sweetalert2";

const NoiseTexture = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/noisy-grid.png");
  opacity: 0.3;
  z-index: -1;
`;

const gradientAnimation = keyframes`
  0% { background-position: 10% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 10% 50%; }
`;

const AnimatedBackground = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #001f3f 40%, #ffffff 100%);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 5s ease infinite;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
`;

const MovingImage = styled("img")(({ translateX = -200 }) => ({
  width: "50%",
  marginRight: "10px",
  objectFit: "cover",
  transform: `translateX(${translateX}px)`,
  transition: "transform 0.2s ease-out",
  position: "relative",
  left: -190,
}));

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};
const images = [remolque1, camion, partner, palio];

const HomePage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [translateX, setTranslateX] = useState(0);
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [user, setUser] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [editedQuestion, setEditedQuestion] = useState('');
  const [editedAnswer, setEditedAnswer] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const ocultarReseña = (id) => {
    updateDoc(doc(db, "reseñas", id), {
      mostrar: false,
      validada: false,
    });
  };

  const ocultarFaq = async (faqId) => {
    const faqDoc = doc(db, 'FAQS', faqId);
    await updateDoc(faqDoc, { oculta: true });
    setFaqs(faqs.filter((faq) => faq.id !== faqId));
  };

  useEffect(() => {
    const faqsCollection = collection(db, "FAQS");
    
    const q = query(
      faqsCollection,
      where("oculta", "==", false),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const faqsData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        fecha: doc.fecha ? faqsData.fecha.toDate().toLocaleDateString() : null, 

      }));
      setFaqs(faqsData);
    });
    return () => unsubscribe();
  }, []);


  useEffect(() => {
    const reviewsCollection = collection(db, "reseñas");
    
    const q = query(
      reviewsCollection,
      where("mostrar", "==", true),
      where("validada", "==", true)
    );
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const reviewsData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      
      const sortedReviewsData = reviewsData.sort((a, b) => a.reseña.length - b.reseña.length);
      
      setReviews(sortedReviewsData);
    });
  
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setTranslateX(scrollPosition * 0.3);
      setShowFloatingButton(scrollPosition > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleEdit = (index, pregunta, respuesta) => {
    setEditIndex(index);
    setEditedQuestion(pregunta);
    setEditedAnswer(respuesta);
  };

  const handleSave = (id) => {
     updateFaq(id, editedQuestion, editedAnswer); 
    setEditIndex(-1);
    setEditedQuestion('');
    setEditedAnswer('');
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe(); 
  }, []);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": faqs.map(faq => ({
      "@type": "Question",
      "name": faq.pregunta,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": faq.respuesta,
      },
      "dateCreated": faq.fechaCreacion instanceof Date ? faq.fechaCreacion.toISOString() : undefined,
      "dateModified": faq.fechaModificacion instanceof Date ? faq.fechaModificacion.toISOString() : undefined,
    })),
  };
  
  const updateFaq = async (id, pregunta, respuesta) => {
    try {
      const faqRef = doc(db, 'FAQS', id); 
      await updateDoc(faqRef, {
        pregunta: pregunta,
        respuesta: respuesta,
        fechaModificacion: new Date(), 
      });
    } catch (error) {
      console.error("Error actualizando la FAQ: ", error);
    }
  };

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    
      <Helmet>
        <link rel="canonical" href="https://www.auxiliomecanicotaty.com/" />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Auxilio mecánico Taty",
        "image": "https://scontent-eze1-1.xx.fbcdn.net/v/t39.30808-6/341146009_114339348295777_5718900720242747441_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=6ee11a&_nc_eui2=AeG5UkpbT6J7Y43CnCqMXq7uTMdHROr-Q2tMx0dE6v5DayCXRMMhR8jZyS9uFj7nCN-AgkMnPogz0bvIWI-0-Unk&_nc_ohc=UtpFrMq7SbQQ7kNvgGl3NVj&_nc_ht=scontent-eze1-1.xx&_nc_gid=A7EgKOJaR9R7yP2zwmz41bU&oh=00_AYBRgPPa1BlgJre4mkUzpn1vlrW6OtJGML2sdRmWDIJsUw&oe=671690EC",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Córdoba",
          "addressRegion": "Córdoba",
          "postalCode": "5000",
          "streetAddress": "Felipe II 1640, X5012IRL, Córdoba"
        },
        "telephone": "+5493512176258",
        "url": "https://www.auxiliomecanicotaty.com/",
        "openingHours": "Mo-Su 00:00-23:59",
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "5.0",
          "ratingCount": "18"
        },
        "sameAs": [
          "https://www.facebook.com/auxiliomecanicotaty",
          "https://www.instagram.com/auxiliomecanicotaty"
        ]
      }
      `}
        </script>
      </Helmet>
      <div
        style={{ position: "relative", minHeight: "100vh", overflow: "hidden" }}
      >
        <AnimatedBackground />
        <NoiseTexture />
        <meta
          property="og:title"
          content="Auxilio mecánico Taty - Grúas en Córdoba"
        />
        <meta
          property="og:description"
          content="Servicio rápido y confiable de grúa en Córdoba. ¡Pedí tu grúa ya!"
        />

        <Box
          sx={{
            position: "absolute",
            zIndex: -1,
            height: "720px",
            borderEndEndRadius: "700px",
            borderEndStartRadius: "5px",
            width: "100%",

            overflow: "hidden",
            boxShadow: "0px 50px 50px rgba(0, 0, 0, 0.7)",
          }}
        >
          <Slider {...carouselSettings}>
            {images.map((image, index) => (
              <div key={index}>
                <motion.img
                  src={image}
                  data-aos="fade-up"
                  alt={`Imagen ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "1100px",
                    objectFit: "cover",
                    filter: "blur(5px)",
                  }}
                  initial={{ opacity: 0, scale: 1 }}
                  animate={{ opacity: 1, scale: 1.0 }}
                  exit={{ opacity: 0, scale: 1 }}
                  transition={{ duration: 3 }}
                />
              </div>
            ))}
          </Slider>
        </Box>

        <Container
          maxWidth="lg"
          sx={{
            textAlign: "left",
            paddingTop: "80px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h1"
              gutterBottom
              sx={{
                fontFamily: '"Merriweather"',
                fontSize: { xs: "2.5rem", md: "7rem" },
                fontWeight: "bold",
                background: "linear-gradient(15deg, #ecdb8b, #ffffff)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                lineHeight: "1.2",
                overflowWrap: "break-word",
                margin: 0,
              }}
            >
              <span style={{ display: "block" }}>Auxilio</span>
              <span style={{ display: "block" }}>Mecánico</span>
              <span style={{ display: "block" }}>Taty</span>
            </Typography>

            <motion.img
              src={logoTaty}
              alt="Logo grúa auxilio"
              style={{
                width: "39%",
                position: "absolute",
                marginLeft: "46%",
                marginBottom: "5%",
              }}
              animate={{ y: [0, -30, 0] }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
                repeatDelay: 5,
              }}
              whileHover={{ y: -49 }}
            />
          </Box>

          <Typography
            variant="h2"
            sx={{
              fontFamily: '"Merriweather"',
              fontSize: { xs: "1.5rem", md: "2.5rem" },
              mt: 1,
              color: "#ffffff",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
            }}
          >
            Servicio de grúa y auxilio mecánico en Córdoba capital
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              onClick={() => (window.location.href = "tel:+5493512176258")}
              sx={{
                fontSize: { xs: "1rem", md: "1.5rem" },
                padding: { xs: "10px 15px", md: "15px 20px" },
                mb: { xs: 2, md: 0 },
                mr: { md: 5 },
                background: "linear-gradient(45deg, #0056b3 30%, #003d7a 90%)",
                borderRadius: "30px",
                position: "relative",
                overflow: "hidden",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.3s ease",
                },
              }}
              startIcon={<FaPhoneAlt />}
            >
              ¡Pedí tu grúa ya!
            </Button>

            <Button
              variant="contained"
              onClick={() =>
                window.open("https://wa.me/5493512176258", "_blank")
              }
              sx={{
                fontSize: { xs: "1rem", md: "1.5rem" },
                padding: { xs: "10px 15px", md: "15px 20px" },
                mb: { xs: 2, md: 0 },
                background: "linear-gradient(45deg, #25D366 30%, #1EBE5A 90%)",
                borderRadius: "30px",
                position: "relative",
                overflow: "hidden",
                boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
                "&:hover": {
                  transform: "scale(1.05)",
                  transition: "transform 0.3s ease",
                },
              }}
              startIcon={<FaWhatsapp />}
            >
              ¡Mandanos un mensaje!
            </Button>
          </Box>
        </Container>

         {showFloatingButton && (
        <Box
          sx={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {isAuthenticated ? (
            <>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "50%",
                  width: "70px",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center", 
                  backgroundColor: "#fc9393",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                }}
                onClick={toggleMenu}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#002fff",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    fontSize: "10px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {isMenuOpen ? "Minimizar" : "Admin Menu"}
                </Typography>
              </Button>

              <Slide direction="up" in={isMenuOpen} mountOnEnter unmountOnExit>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#ffffff",
                    }}
                    startIcon={
                      <MdOutlineReviews
                        style={{
                          fontSize: "45px",
                          marginLeft: "25%",
                          color: "#002fff",
                        }}
                      />
                    }
                    onClick={() => (window.location.href = "/adminReviews")}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#ffffff",
                    }}
                    startIcon={
                      <FaQuestionCircle
                        style={{
                          fontSize: "45px",
                          marginLeft: "25%",
                          color: "#002fff",
                        }}
                      />
                    }
                    onClick={() => (window.location.href = "/adminFAQS")}
                  />
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "50%", width: "60px", height: "60px" }}
                    startIcon={
                      <FaPhoneAlt
                        style={{ fontSize: "45px", marginLeft: "25%" }}
                      />
                    }
                    onClick={() => (window.location.href = "tel:+5493512176258")}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#25D366",
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                    }}
                    startIcon={
                      <FaWhatsapp
                        style={{ fontSize: "45px", marginLeft: "25%" }}
                      />
                    }
                    onClick={() =>
                      window.open("https://wa.me/5493512176258", "_blank")
                    }
                  />
                </Box>
              </Slide>
            </>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
           
              <Button
                variant="contained"
                sx={{ borderRadius: "50%", width: "60px", height: "60px" }}
                startIcon={
                  <FaPhoneAlt
                    style={{ fontSize: "45px", marginLeft: "25%" }}
                  />
                }
                onClick={() => (window.location.href = "tel:+5493512176258")}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#25D366",
                  borderRadius: "50%",
                  width: "60px",
                  height: "60px",
                }}
                startIcon={
                  <FaWhatsapp
                    style={{ fontSize: "45px", marginLeft: "25%" }}
                  />
                }
                onClick={() =>
                  window.open("https://wa.me/5493512176258", "_blank")
                }
              />
            </Box>
                 )}
        </Box>
        )}
      <Box
  sx={{
    backgroundColor: "#a83a3a00",
    marginTop: { xs: "20rem", md: "20.5rem" }, 
    width: '100%',
  }}
>
  <Container 
    sx={{
      textAlign: "center",
      bgcolor: '#555557',
      position: 'relative',
      clipPath: 'polygon(0% 20%, 100% 0%, 100% 100%, 0% 100%)',
      padding: { xs: '20px', md: '40px' },
      color: '#fafafa',
      width: { xs: '100vw', md: '1000vw' },
    }}
  >
    <Typography
      variant="h3"
      gutterBottom
      sx={{
        fontFamily: '"Merriweather"',
        fontSize: { xs: "1.5rem", md: "3rem" },
        color: "#fafafa",
        marginTop: { xs: '27%', md: '3%' },
        rotate: { xs: '0deg', md: '-5deg' }, 
      }}
    >
      Nuestros servicios disponibles 24/7
    </Typography>

    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            lineHeight: "1.2",
            overflowWrap: "break-word",
            fontFamily: '"Candara"',
            fontSize: { xs: "1rem", md: "2.3rem" },
            textAlign: "left",
            color: "#fafafa",
          }}
        >
          Motos, autos, autos clásicos, camionetas, casas rodantes u
          otros tipos de vehículos. Translados de palet.
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Grid container spacing={1} sx={{ marginTop: { xs: "0", md: "10px" } }}>
          <Grid item xs={6} sm={6}>
            <img
              src={auto3}
              alt="Auto remolcado en Córdoba Capital"
              style={{
                width: "100%",
                height: { xs: "auto", md: "100px" },
                objectFit: "cover",
                marginTop: { xs: "0", md: "50px" },
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <img
              src={trafic}
              alt="trafic remolacada en Córdoba Capital"
              style={{
                width: "100%",
                height: { xs: "auto", md: "250px" },
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <img
              src={auto2}
              alt="Vehículo remolcado en Córdoba Capital"
              style={{
                width: "100%",
                height: { xs: "150px", md: "180px" }, 
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <img
              src={camion2}
              alt="Remolque de camion en Córdoba"
              style={{
                width: "100%",
                height: { xs: "120px", md: "150px" }, 
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
</Box>


        <Grid item xs={6} sm={6}>
          <MovingImage
            src={png}
            alt="Servicio de Grua"
            translateX={translateX}
          />
          <Typography
            data-aos="fade-up"
            variant="h4"
            gutterBottom
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              lineHeight: "1.2",
              overflowWrap: "break-word",
              fontFamily: '"Candara"',
              fontSize: { xs: "1.8rem", md: "2.3rem" },
              textAlign: "center",
              color: "#fafafa",
            }}
          >
            Traslados y auxilio las 24 horas los 365 días del año
          </Typography>
        </Grid>

        <Grid item xs={12} md={10}>
  <Grid
    container
    spacing={1} 
    sx={{ marginTop: "10px", justifyContent: "center" }}
  >
 
    <Grid
      item
      xs={12}
      sm={6} 
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "5px",
      }}
    >
      <img
        data-aos="fade-up"
        src={clasico}
        alt="Vehículo clásico remolque"
        style={{
          width: "90%",
          maxHeight: "1500px",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
    </Grid>
    <Grid
      item
      xs={12}
      sm={6} 
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "5px",
      }}
    >
      <img
        data-aos="fade-up"
        src={auto}
        alt="Volkswagen Passat"
        style={{
          width: "80%",
          maxHeight: "1500px",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
    </Grid>

    <Grid
      item
      xs={12}
      sm={4}
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "5px",
      }}
    >
      <img
        src={chata}
        data-aos="fade-up"
        alt="Camioneta remolque"
        style={{
          width: "70%",
          maxHeight: "200px",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
    </Grid>
    <Grid
      item
      xs={12}
      sm={4}
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "5px",
      }}
    >
      <img
        data-aos="fade-up"
        src={carga}
        alt="Camioneta remolcada en Córdoba Capital"
        style={{
          width: "70%",
          maxHeight: "200px",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
    </Grid>
    <Grid
      item
      xs={12}
      sm={4} 
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "5px",
      }}
    >
      <img
        src={chata3}
        alt="Camioneta Auxilio Córdoba"
        data-aos="fade-up"
        style={{
          width: "70%",
          maxHeight: "200px",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
    </Grid>
  </Grid>
</Grid>
<Grid item xs={6} sm={6}>
  <Typography
    data-aos="fade-up"
    variant="h4"
    gutterBottom
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      marginTop: "50px",
      lineHeight: "1.2",
      overflowWrap: "break-word",
      fontFamily: '"Candara"',
      fontSize: { xs: "1.8rem", md: "2.3rem" },
      textAlign: "center",
      color: "#fafafa",
    }}
  >
    Realizamos todo tipo de traslados.
  </Typography>
</Grid>

        <Box
          sx={{
            backgroundColor: "#444040",
            padding: "50px 30px",
            marginTop: "50px",
            borderRadius: "150px 0px 0px 0px",
            boxShadow: "50px 40px 25px rgba(0, 0, 0, 0.2)",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Container maxWidth="lg" sx={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              gutterBottom
              data-aos="fade-up"
              sx={{
                fontFamily: '"Merriweather"',
                fontSize: { xs: "1.5rem", md: "3rem" },
                color: "#ffffff",
                marginBottom: "30px",
              }}
            >
              <div>
                <StarIcon /> Lo que dicen nuestros clientes <StarIcon />
              </div>
            </Typography>
            <Grid container spacing={3}>
              {reviews.map((review, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card
                    data-aos="fade-up"
                    sx={{
                      backgroundColor: "#f8f9fa",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0px 30px 30px rgba(0, 0, 0, 0.78)",
                    }}
                  >
                    {user && (
                      <Button
                        style={{ marginLeft: "90%" }} 
                        color="secondary"
                        onClick={() => {
                          Swal.fire({
                            title: "¿Estás seguro?",
                            text: "La reseña será ocultada.",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Sí, ocultar",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              ocultarReseña(review.id); 
                              Swal.fire(
                                "Ocultada",
                                "La reseña ha sido ocultada.",
                                "success"
                              );
                            }
                          });
                        }}
                      >
                        ❌
                      </Button>
                    )}
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        {(review.nombre ? review.nombre : "") +
                          " " +
                          (review.apellido ? review.apellido : "")}
                      </Typography>
                      <Typography variant="body1" sx={{ marginBottom: "10px" }}>
                        {review.reseña}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        5.0
                        <StarIcon sx={{ color: "#f0ca20" }} />
                        <StarIcon sx={{ color: "#f0ca20" }} />
                        <StarIcon sx={{ color: "#f0ca20" }} />
                        <StarIcon sx={{ color: "#f0ca20" }} />
                        <StarIcon sx={{ color: "#f0ca20" }} />
                      </Box>

                      {/* Mostrar procedencia */}
                      <Typography variant="body2" sx={{ marginTop: "10px" }}>
                        Procedencia:
                        {review.procedencia === "Google" ? (
                          <FaGoogle style={{ marginLeft: "5px" }} />
                        ) : (
                          " Web"
                        )}
                      </Typography>

                      {/* Mostrar el botón de eliminar si el usuario está logueado */}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            
            <Box
              sx={{
                marginTop: "5%",
                color: "#ffffff",
                padding: "40px 0",
                textAlign: "center",
                width: "100%", 
                position: "relative",
                left: 0, 
              }}
            >
              <Box sx={{ textAlign: "center", my: 4 }}>
                <Button
                  startIcon={
                    <FaGoogle style={{ fontSize: "35px", marginLeft: "25%" }} />
                  }
                  variant="contained"
                  sx={{
                    backgroundColor: "#3abb4f",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#218838",
                    },
                  }}
                  onClick={() =>
                    window.open(
                      "https://www.google.com/search?hl=es&gl&q=Auxilio+Taty,+Felipe+II+1640,+X5012IRL+X5012IRL,+Córdoba,+Argentina&ludocid=7547232484063848633&lsig=AB86z5WO3VNsQCzem5yRVs4fN8dp&hl=es&gl=419#lrd=0x94329979b4674fe9:0x68bd27f511ea28b9,1",
                      "_blank"
                    )
                  }
                >
                  ¡Apóyanos con una reseña en google!
                </Button>
              </Box>

           
    
              <Box sx={{ textAlign: "center", my: 2 }}>
                <Typography variant="h6">-o-</Typography>
              </Box>
              <Box sx={{ textAlign: "center", my: 4 }}>
                <Button
                  startIcon={
                    <FaWebAwesome
                      style={{ fontSize: "35px", marginLeft: "25%" }}
                    />
                  }
                  variant="contained"
                  sx={{
                    backgroundColor: "#007bff",
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#0056b3",
                    },
                  }}
                  onClick={() => {
                    window.location.href = "/dejatucomentario";
                  }}
                >
                  ¡Dejanos una reseña en nuestra web!
                </Button>
              </Box>
            </Box>
     

       </Container>
        </Box>
        <Box
          sx={{
            backgroundColor: "#8d3d3d",
            padding: "50px 30px",
            marginTop: "50px",
            boxShadow: "50px 40px 25px rgba(0, 0, 0, 0.2)",
            position: "relative",
            overflow: "hidden",
          }}
        >
      <Typography
        variant="h5"
        gutterBottom
        data-aos="fade-up"
        sx={{
          fontFamily: '"Merriweather"',
          fontSize: { xs: "1.8rem", md: "3rem" },
          color: "#ffffff",
          marginBottom: "30px",
        }}
      >
        Preguntas frecuentes:
      </Typography>
      <Grid container spacing={2}>
        {faqs.map((faq, index) => (
          <Grid item xs={12} key={index}> 
         <Card
  data-aos="fade-up"
  sx={{
    backgroundColor: "#f8f9fa",
    padding: { xs: "10px", md: "20px" },
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
  }}
>
  {/* Contenedor para los botones de edición y ocultar */}
  {user && (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Button
        color="primary"
        onClick={() => handleEdit(index, faq.pregunta, faq.respuesta)}
      >
        <EditIcon />
      </Button>

      <Button
        color="secondary"
        onClick={() => {
          Swal.fire({
            title: "¿Estás seguro?",
            text: "La pregunta frecuente será ocultada.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sí, ocultar",
          }).then((result) => {
            if (result.isConfirmed) {
              ocultarFaq(faq.id);
              Swal.fire(
                "Ocultada",
                "La pregunta frecuente ha sido ocultada.",
                "success"
              );
            }
          });
        }}
      >
        ❌
      </Button>
    </Box>
  )}

  <CardContent>
    {editIndex === index ? (
      <>
        <TextField
          variant="outlined"
          value={editedQuestion}
          onChange={(e) => setEditedQuestion(e.target.value)}
          sx={{ width: '100%', marginBottom: '10px' }}
        />
        <TextField
          variant="outlined"
          value={editedAnswer}
          onChange={(e) => setEditedAnswer(e.target.value)}
          sx={{ width: '100%', marginBottom: '10px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave(faq.id)}
        >
          Guardar
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => setEditIndex(-1)}
          sx={{ marginLeft: '10px' }}
        >
          Cancelar
        </Button>
      </>
    ) : (
      <>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "1.1rem", md: "1.5rem" },
            paddingBottom: '4%',
          }}
        >
          {faq.pregunta}
        </Typography>
        <Typography
          variant="body1"
          sx={{ fontSize: { xs: "1rem", md: "1.1rem" }, marginBottom: "10px" }}
        >
          {faq.respuesta}
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "right", color: "gray" }}
        >
          {faq.fechaModificacion?.toDate().toLocaleDateString("es-ES")}
        </Typography>
      </>
    )}
  </CardContent>
</Card>
          </Grid>
        ))}
      </Grid>
    </Box>
        <Box
          sx={{
            backgroundColor: "#2b2b2b",
            color: "#ffffff",
            padding: "40px 0",
            textAlign: "center",
          }}
        >
          <Container maxWidth="lg" sx={{ my: 4 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Encontranos por acá
            </Typography>
            <Box sx={{ width: "100%", height: "400px", overflow: "hidden" }}>
              <iframe
                title="Maps Auxilio Grúa"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3405.8757964825245!2d-64.15585308836474!3d-31.38998807416664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94329979b4674fe9%3A0x68bd27f511ea28b9!2sAuxilio%20Taty!5e0!3m2!1ses-419!2sar!4v1729153646000!5m2!1ses-419!2sar"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
          </Container>
          <Container maxWidth="lg">
            <Typography variant="body1" sx={{ mb: 3 }}>
              © 2024 Auxilio Mecánico Taty. Todos los derechos reservados.
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <a
                href="https://www.facebook.com/profile.php?id=100091587621564"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook style={{ fontSize: "24px", cursor: "pointer" }} />
              </a>
              <a
                href="https://wa.me/5493512176258"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp style={{ fontSize: "24px", cursor: "pointer" }} />
              </a>
            </Box>
          </Container>
        </Box>
      </div>
    </>
  );
};

export default HomePage;
