import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Si estás utilizando create-react-app sin configuración adicional
// no deberías tener un service worker en el archivo src/serviceWorker.js, 
// pero si lo tienes, asegúrate de no importarlo.

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Si deseas eliminar el service worker, asegúrate de no usarlo en la aplicación.
reportWebVitals();
