import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import styled, { keyframes } from "styled-components";
import logoTaty from "../img/logo Taty.png";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

const NoiseTexture = styled.div`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/noisy-grid.png");
  opacity: 0.3;
  z-index: -1;
`;

const gradientAnimation = keyframes`
  0% { background-position: 10% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 10% 50%; }
`;

const AnimatedBackground = styled(Box)`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #001f3f 40%, #ffffff 100%);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 5s ease infinite;
`;

const AdminFAQS = () => {
  const [pregunta, setPregunta] = useState('');
  const [respuesta, setRespuesta] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (pregunta.trim() && respuesta.trim()) {
      try {
        const faqData = {
          pregunta: pregunta.trim(),
          respuesta: respuesta.trim(),
          oculta: false,
          fechaCreacion: new Date(),
          fechaModificacion: new Date()
        };

        await addDoc(collection(db, 'FAQS'), faqData);
        console.log('Pregunta guardada:', faqData);
        Swal.fire({
          title: 'Éxito',
          text: 'Pregunta/respuesta guardadas.',
          icon: 'success',
        });
        setPregunta('');
        setRespuesta('');
      } catch (error) {
        console.error('Error al guardar ', error);
      }
    } else {
      Swal.fire({
        title: 'Error',
        text: 'Faltan datos.',
        icon: 'error',
      });
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": pregunta,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": respuesta,
      }
    }]
  };

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh', 
          position: 'relative', 
          p: { xs: 2, sm: 4 } 
        }}
      >
        <AnimatedBackground />
        <NoiseTexture />

        <Box sx={{ position: 'relative', textAlign: 'center', width: '100%' }}>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <motion.img
              src={logoTaty}
              alt="Logo Taty"
              style={{
                width: "100%", 
                maxWidth: "150px", 
                marginBottom: '5%', 
                position: 'relative',
              }}
              animate={{ y: [0, -30, 0] }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut",
                repeatDelay: 5,
              }}
              whileHover={{ y: -10 }}
            />
          </Link>

          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ 
              maxWidth: { xs: '100%', sm: 600 }, 
              mx: 'auto', 
              p: { xs: 2, sm: 3 }, 
              backgroundColor: 'rgba(36, 76, 209, 0.8)', 
              borderRadius: '8px', 
              boxShadow: 3 
            }}
          >
            <Typography variant="h5" sx={{ mb: 2, color: '#ffff' }}>
              Escribir preguntas y respuestas
            </Typography>
            <TextField
              label="Pregunta"
              variant="outlined"
              fullWidth
              value={pregunta}
              onChange={(e) => setPregunta(e.target.value)}
              sx={{ mb: 2 }}
              InputProps={{
                style: { color: '#ffffff' }
              }}
              InputLabelProps={{
                style: { color: '#ffffff' }
              }}
            />
            <TextField
              label="Respuesta"
              variant="outlined"
              fullWidth
              value={respuesta}
              onChange={(e) => setRespuesta(e.target.value)}
              sx={{ mb: 2 }}
              InputProps={{
                style: { color: '#ffffff', height: '150px' }
              }}
              InputLabelProps={{
                style: { color: '#ffffff' }
              }}
            />

            <Button 
              type="submit" 
              variant="contained" 
              sx={{ 
                mt: 2, 
                color: '#ffff', 
                backgroundColor: '#07521d',
                '&.Mui-disabled': {
                  backgroundColor: '#9e9e9e',
                  color: 'rgba(255, 255, 255, 0.5)',
                },
              }} 
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminFAQS;
