import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AdminLogin from './components/AdminLogin'; 
import ReseñaForm from './components/ReseñaForm';
import AdminReviews from "./components/AdminReviews";
import HomePage from './components/HomePage'; 
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase"
import AdminFAQS from "./components/AdminFAQS";
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);


  return (
    <Router>
    <Routes>
      <Route path="/loginTaty" element={<AdminLogin />} />
      <Route path="/adminReviews" element={<AdminReviews />} />
      <Route path="/adminFAQS" element={<AdminFAQS />} />
      <Route path="/dejatucomentario" element={<ReseñaForm />} />
      <Route path="/HomePage" element={<HomePage />} />
      <Route path="*" element={<Navigate to="/HomePage" />} />
    </Routes>
  </Router>
  );
};

export default App;
