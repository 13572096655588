// src/components/AdminLogin.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase"; 
import styled, { keyframes } from "styled-components";
import {
  Box,
  Typography,
  Card,
  TextField,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";
import logoTaty from "../img/logo Taty.png";

const NoiseTexture = styled.div`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/noisy-grid.png");
  opacity: 0.3;
  z-index: -1;
`;

const gradientAnimation = keyframes`
  0% { background-position: 10% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 10% 50%; }
`;

const AnimatedBackground = styled(Box)`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #001f3f 40%, #ffffff 100%);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 5s ease infinite;
`;

const StyledCard = styled(Card)`
  max-width: 400px; 
  margin: auto; 
  padding: 20px; 
  background-color: rgba(7, 122, 236, 0.8); /* Usa rgba para darle un efecto de translucidez */
  border-radius: 10px; 
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); 
`;


const StyledButton = styled(Button)`
  background-color: #28a745; /* Color verde para el botón */
  color: white; 
  &:hover {
    background-color: #218838; /* Color más oscuro al pasar el mouse */
  }
`;

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem("auth", true); 
      navigate("/HomePage"); 
    } catch (error) {
      setError("Usuario o contraseña incorrectos.");
      console.log(error,"error");
    }
  };

  return (
    <div style={{ textAlign: "center", position: "relative", height: "100vh" }}>
      <AnimatedBackground />
      <NoiseTexture />
      <StyledCard >
        <Typography
          variant="h4"
          style={{
            background: "linear-gradient(15deg, #11034e, #1e06f8)",
            WebkitBackgroundClip: "text",
            color: "transparent",
            marginBottom: "20px",
          }}
        >
          Panel de administración
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            type="email"
            placeholder="Correo electrónico"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            InputProps={{
              style: { backgroundColor: "#ffffff" }, 
            }}
          />
          <TextField
            type="password"
            placeholder="Contraseña"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              style: { backgroundColor: "#ffffff" }, 
            }}
          />
          <StyledButton type="submit" fullWidth>
            Ingresar
          </StyledButton>
        </form>
        {error && <p style={{ color: "red" }}>{error}</p>}
      </StyledCard>
      <motion.button
  onClick={() => {
    window.location.href = "/HomePage";
  }}
  style={{
    background: "none", 
    border: "none", 
    padding: 0, 
    cursor: "pointer", 
    width: "25%", 
    position: "relative",
    marginTop: '5%',
  }}
>
  <motion.img
    src={logoTaty}
    alt="Logo grúa auxilio"
    style={{
      width: "100%", 
    }}
    animate={{ y: [0, -30, 0] }}
    transition={{
      duration: 1,
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut",
      repeatDelay: 5, 
    }}
    whileHover={{ y: -49 }} 
  />
</motion.button>
    </div>
  );
};

export default AdminLogin;
