// StarRating.js
import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const StarRating = ({ rating, onRate, onReset }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const handleStarClick = (value) => {
    onRate(value);
  };

  const handleStarMouseEnter = (value) => {
    setHoverRating(value);
  };

  const handleStarMouseLeave = () => {
    setHoverRating(0);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {[1, 2, 3, 4, 5].map((value) => (
        <IconButton
          key={value}
          onClick={() => handleStarClick(value)}
          onMouseEnter={() => handleStarMouseEnter(value)}
          onMouseLeave={handleStarMouseLeave}
        >
          {hoverRating >= value || rating >= value ? (
            <StarIcon style={{ color: '#FFD700' }} />
          ) : (
            <StarBorderIcon style={{ color: '#ccc' }} />
          )}
        </IconButton>
      ))}
      <button onClick={onReset} style={{ marginLeft: '10px', display: rating > 0 ? 'inline' : 'none' }}>
        Resetear
      </button>
    </div>
  );
};

export default StarRating;
