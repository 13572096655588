import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import styled, { keyframes } from "styled-components";
import Swal from 'sweetalert2';
import StarRating from './StarRating';
import logoTaty from "../img/logo Taty.png";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

const NoiseTexture = styled.div`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/noisy-grid.png");
  opacity: 0.3;
  z-index: -1;
`;

const gradientAnimation = keyframes`
  0% { background-position: 10% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 10% 50%; }
`;

const AnimatedBackground = styled(Box)`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #001f3f 40%, #ffffff 100%);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 5s ease infinite;
`;

const ReseñaForm = () => {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [reseña, setReseña] = useState('');
  const [anonimo, setAnonimo] = useState(false);
  const [hasReviewed, setHasReviewed] = useState(false); 
  const [rate, setRate] = useState(0); 

  const maxCaracteres = 350;

  const handleRate = (value) => {
    setRate(value);
  };

  const handleReset = () => {
    setRate(0);
  };

  useEffect(() => {
    const reviewStatus = localStorage.getItem('hasReviewed');
    if (reviewStatus) {
      setHasReviewed(true);
    }
  }, []);

  const handleReseñaChange = (event) => {
    setReseña(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (hasReviewed) {
      Swal.fire({
        title: 'Error',
        text: 'Ya has enviado una reseña.',
        icon: 'error',
      });
      return;
    }

    if (!nombre && !apellido) {
      setAnonimo(true);
    }
    if (reseña.length < 5) {
      Swal.fire({
        title: 'Error',
        text: 'Especifique su reseña',
        icon: 'error',
      });
      return;
    }

    const reseñaData = {
      nombre: nombre.trim() || null,
      apellido: apellido.trim() || null,
      reseña: reseña.trim(),
      anonimo,
      procedencia: 'web',
      estrellas: rate,
      mostrar: false,
      validada: false
    };

    try {
      await addDoc(collection(db, 'reseñas'), reseñaData);
      console.log('Reseña guardada:', reseñaData);

      localStorage.setItem('hasReviewed', 'true');
      setHasReviewed(true);

      Swal.fire({
        title: 'Esperando aprobación',
        text: 'Gracias por tu reseña. Taty la revisará a la brevedad.',
        icon: 'success',
        timer: 5000,
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: 'Volver a la página de inicio',
        onClose: () => {
          window.location.href = '/'; 
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/'; 
        }
      });

      setNombre('');
      setApellido('');
      setReseña('');
      setAnonimo(false);
      setRate(5);
    } catch (error) {
      console.error('Error al guardar la reseña:', error);
    }
  };

  const caracteresRestantes = maxCaracteres - reseña.length;
  let color = 'green';
  if (caracteresRestantes <= 150) {
    color = 'orange'; 
  }
  if (caracteresRestantes < 50) {
    color = 'red'; 
  }

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        position: 'relative', 
        p: { xs: 2, sm: 4 } 
      }}
    >
      <AnimatedBackground />
      <NoiseTexture />

      <Box sx={{ position: 'relative', textAlign: 'center', width: '100%' }}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <motion.img
            src={logoTaty}
            alt="Logo Taty"
            style={{
              width: "100%", 
              maxWidth: "150px", 
              marginBottom: '5%', 
              position: 'relative',
            }}
            animate={{ y: [0, -30, 0] }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
              repeatDelay: 5,
            }}
            whileHover={{ y: -10 }}
          />
        </Link>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ 
            maxWidth: { xs: '100%', sm: 600 }, 
            mx: 'auto', 
            p: { xs: 2, sm: 3 }, 
            backgroundColor: 'rgba(36, 76, 209, 0.8)', 
            borderRadius: '8px', 
            boxShadow: 3 
          }}
        >
          <Typography variant="h5" sx={{ mb: 2, color: '#ffff' }}>
            Deja tu reseña
          </Typography>
          <TextField
            label="Nombre (no obligatorio)"
            variant="outlined"
            fullWidth
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              style: { color: '#ffffff' }
          }}
          InputLabelProps={{
              style: { color: '#ffffff' }
          }}
          />
          <TextField
            label="Apellido (no obligatorio)"
            variant="outlined"
            fullWidth
            value={apellido}
            onChange={(e) => setApellido(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              style: { color: '#ffffff' }
          }}
          InputLabelProps={{
              style: { color: '#ffffff' }
          }}
          />
          <TextField
            label="Reseña *"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={reseña}
            onChange={handleReseñaChange}
            inputProps={{ maxLength: maxCaracteres }}
            sx={{ mb: 2 }}
            InputProps={{
              style: { color: '#ffffff' }
          }}
          InputLabelProps={{
              style: { color: '#ffffff' }
          }}
          />
          <Typography style={{ color: color }}>
            {caracteresRestantes} {caracteresRestantes === 1 ? 'carácter restante' : 'caracteres restantes'}
          </Typography>
          <StarRating rating={rate} onRate={handleRate} onReset={handleReset} />

          <Button 
            type="submit" 
            variant="contained" 
            disabled={reseña.length < 5 || hasReviewed} 
            sx={{ 
              mt: 2, 
              color: '#ffff', 
              backgroundColor: '#07521d',
              '&.Mui-disabled': {
                backgroundColor: '#9e9e9e',
                color: 'rgba(255, 255, 255, 0.5)',
              },
            }} 
          >
            Enviar Reseña
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReseñaForm;
