import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom'; 
import { db } from '../firebase';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import styled, { keyframes } from "styled-components";
import Swal from 'sweetalert2';
import logoTaty from "../img/logo Taty.png";
import { motion } from 'framer-motion';

const Logo = styled.img`
  width: 100px; 
  height: auto;
  margin-bottom: 20px;
  cursor: pointer; 
  transition: transform 0.2s;
  
  &:hover {
    transform: scale(1.1); /* Efecto hover */
  }
`;

const NoiseTexture = styled.div`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  background-image: url("https://www.transparenttextures.com/patterns/noisy-grid.png");
  opacity: 0.3;
  z-index: -1;
`;

const gradientAnimation = keyframes`
  0% { background-position: 10% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 10% 50%; }
`;

const AnimatedBackground = styled(Box)`
  position: fixed; 
  top: 0; 
  left: 0; 
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(135deg, #001f3f 40%, #ffffff 100%);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 5s ease infinite;
`;

const StyledCard = styled(Card)`
  width: 100%; /* Ajustar a 100% del contenedor para móvil */
  max-width: 400px; /* Máximo ancho para desktop */
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.2s;
  
  &:hover {
    transform: scale(1.02);
  }
`;

const StyledButton = styled(Button)`
  font-size: 1.1rem;
  padding: 10px 20px;
  transition: background-color 0.3s;
`;

const DisapproveButton = styled(Button)`
  font-size: 1.1rem;
  padding: 10px 20px;
  border-color: #dc3545;
  color: #dc3545;
`;

const NextReviewButton = styled(Button)`
  margin-top: 20px;
`;

const AdminReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [user, setUser] = useState(null);

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchReviews = async () => {
      const q = query(collection(db, 'reseñas'), where('validada', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedReviews = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReviews(fetchedReviews);
    };

    fetchReviews();
  }, []);

  const handleApproveReview = async (id) => {
    const reviewRef = doc(db, 'reseñas', id);
    
    try {
      await updateDoc(reviewRef, { mostrar: true, validada: true });
      Swal.fire({
        title: 'Éxito',
        text: 'La reseña ha sido aprobada.',
        icon: 'success',
      });
      
      setCurrentReviewIndex(currentReviewIndex + 1);
      setReviews(prevReviews => prevReviews.filter(review => review.id !== id));
    } catch (error) {
      console.error('Error al actualizar la reseña:', error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudo aprobar la reseña.',
        icon: 'error',
      });
    }
  };

  const handleDisapproveReview = async (id) => {
    const reviewRef = doc(db, 'reseñas', id);

    try {
      await updateDoc(reviewRef, { mostrar: false, validada: true });
      Swal.fire({
        title: 'Éxito',
        text: 'La reseña ha sido desaprobada.',
        icon: 'success',
      });
      
      setCurrentReviewIndex(currentReviewIndex + 1);
      setReviews(prevReviews => prevReviews.filter(review => review.id !== id));
    } catch (error) {
      console.error('Error al desaprobar la reseña:', error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudo desaprobar la reseña.',
        icon: 'error',
      });
    }
  };

  const handleNextReview = () => {
    setCurrentReviewIndex(currentReviewIndex + 1);
  };

  if (!user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h5">Debes estar logueado para acceder a este panel.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', position: 'relative', flexDirection: 'column' }}>
      <AnimatedBackground />
      <NoiseTexture />
      
      <Link to="/" style={{ textDecoration: 'none' }}>
          <motion.img
            src={logoTaty}
            alt="Logo Taty"
            style={{
              width: "100%", 
              maxWidth: "150px", 
              marginBottom: '5%', 
              position: 'relative', 
            }}
            animate={{ y: [0, -30, 0] }}
            transition={{
              duration: 1,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "easeInOut",
              repeatDelay: 5, 
            }}
            whileHover={{ y: -10 }} 
          />
        </Link>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: isMobile ? '90%' : '400px' }}>
        {reviews.length === 0 ? (
          <Typography variant="h6" sx={{ marginTop: '5%' }}>No hay reseñas pendientes de aprobación.</Typography>
        ) : (
          <>
            {currentReviewIndex < reviews.length ? (
              <StyledCard key={reviews[currentReviewIndex].id}>
                <CardContent>
                  <Typography variant="h6" sx={{ marginTop: '5%' }}>
                    Reseña de: { 
                      (reviews[currentReviewIndex].nombre ? reviews[currentReviewIndex].nombre : '') +
                      (reviews[currentReviewIndex].apellido ? ' ' + reviews[currentReviewIndex].apellido : '') || 
                      'Anónimo' 
                    }
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: '5%' }}>Mensaje: {reviews[currentReviewIndex].reseña}</Typography>
                  <Typography variant="body1" sx={{ marginTop: '5%' }}>Estrellas: {reviews[currentReviewIndex].estrellas}</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <StyledButton variant="outlined" onClick={() => handleApproveReview(reviews[currentReviewIndex].id)} sx={{ marginRight: 1 }}>
                      Aprobar Reseña
                    </StyledButton>
                    <DisapproveButton variant="outlined" onClick={() => handleDisapproveReview(reviews[currentReviewIndex].id)}>
                      Desaprobar Reseña
                    </DisapproveButton>
                  </Box>
                  {currentReviewIndex < reviews.length - 1 ? (
                    <NextReviewButton variant="outlined" onClick={handleNextReview} sx={{ marginTop: '10%' }}>
                      Ver siguiente reseña (restantes: {reviews.length - currentReviewIndex - 1}) ➔
                    </NextReviewButton>
                  ) : (
                    <Typography variant="body1" sx={{ marginTop: 2 }}>No hay más reseñas después de esta.</Typography>
                  )}
                </CardContent>
              </StyledCard>
            ) : (
              <Typography variant="h6">No hay más reseñas pendientes de aprobación.</Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdminReviews;
